// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bevel-js": () => import("./../../../src/pages/bevel.js" /* webpackChunkName: "component---src-pages-bevel-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knives-js": () => import("./../../../src/pages/knives.js" /* webpackChunkName: "component---src-pages-knives-js" */),
  "component---src-pages-prices-js": () => import("./../../../src/pages/prices.js" /* webpackChunkName: "component---src-pages-prices-js" */),
  "component---src-pages-scissors-js": () => import("./../../../src/pages/scissors.js" /* webpackChunkName: "component---src-pages-scissors-js" */),
  "component---src-pages-sharpness-js": () => import("./../../../src/pages/sharpness.js" /* webpackChunkName: "component---src-pages-sharpness-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

